import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MembershipDto, MembershipPeriod, PatientDto, ProgramType } from '@bemum/api-interfaces';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Subscription } from 'rxjs';
import { AuthentificationService } from '../../core/services/authentification.service';
import { PatientsService } from '../../core/services/patients.service';

enum State {
  LOADING,
  UNSUBSCRIBED,
  WILL_UNSUBSCRIBE,
  SUBSCRIBED,
  EDIT_SUBSCRIPTION,
  EDIT_SUBSCRIPTION_PACK,
  CREATE_SUBSCRIPTION,
  CREATE_SUBSCRIPTION_PACK,
  CREATE_SUBSCRIPTION_PACK_OPTION,
}

@Component({
  selector: 'bemum-subscription-billing',
  templateUrl: './subscription-billing.component.html',
  styleUrls: ['./subscription-billing.component.scss'],
})
export class SubscriptionBillingComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  @Input() public patient: PatientDto;

  public currentState = State.LOADING;
  public State = State;

  hasActiveSubscription = false;

  withEssentielSupplements = false;
  withEssentielCoaching = false;
  withCoachingOption = false;
  withStructurantOption = false;
  withProtecteurOption = false;
  totalOptions = 0;
  totalShipping = 6.5;
  totalPickupShipping = 5;
  isPickup = false;
  membershipPeriod = MembershipPeriod.MONTHLY;
  programType = ProgramType.ESSENTIAL;

  essentialTooltip = `Vous voulez réduire votre accompagnement dans BeMum ? Nous vous proposons de commencer par annuler votre option "Spécificité coaching."`;

  // isVisibleCoachingDropOutModal = false;
  // isVisibleSupplementsDropOutModal = false;

  isVisible = false;
  modalType = 'essentiel';
  isVisibleShopifyManageSubModal = false;

  currentMembershipText = '';
  futureMembershipText = '';

  hasJustUpdatedSubscription = false;

  url = '';
  pricingTable = {
    essentialSupplements: {
      monthly: 31.5,
      three_months: 80,
      six_months: 151,
    },
    essentialCoaching: {
      monthly: 30.0,
      three_months: 76.5,
      six_months: 144,
    },
    protecteurOption: {
      monthly: 19.8,
      three_months: 50.5,
      six_months: 95,
    },
    structurantOption: {
      monthly: 16.5,
      three_months: 42,
      six_months: 80,
    },
    coachingOption: {
      monthly: 30,
      three_months: 90,
      six_months: 180,
    },
    agePack: {
      monthly: 81.3,
      three_months: 207,
    },
    sopkPack: {
      monthly: 78,
      three_months: 199,
    },
  };

  choiceRegister = false;
  selectedCheckbox!: string;
  monthSelect!: string;
  totalPackOptions = 0;

  constructor(
    private patientService: PatientsService,
    private notification: NzNotificationService,
    private authentificationService: AuthentificationService
  ) {}

  /**
   * On init, check if patient has active subscription
   */
  ngOnInit(): void {
    if (this.patient) {
      this.initState();
    }
    this.url = window.location.href;
  }

  /** Reset the currentState based on the patient's membership */
  initState() {
    // Subscribe to current user to get update in membership when user changes subscription
    const sub = this.patientService.get(this.patient.id).subscribe((patient) => {
      this.patient = patient;
      this.authentificationService.currentUser = this.patient;
      this.membershipPeriod = patient.currentMembership.membershipPeriod;
      this.programType = patient.currentMembership.programType;
      this.isPickup = patient.isPickup;

      if (this.patient && this.patient.currentMembership && this.patient.futureMembership) {
        const { currentMembership, futureMembership } = this.patient;

        this.currentMembershipText = this.getSubscriptionText(currentMembership);
        this.futureMembershipText = this.getSubscriptionText(futureMembership, true);

        const isBooleanKey = (key: string) => typeof currentMembership[key] === 'boolean';

        const noCurrentSubscription = Object.keys(currentMembership)
          .filter(isBooleanKey)
          .every((key) => currentMembership[key] === false);

        const noFutureSubscription = Object.keys(futureMembership)
          .filter(isBooleanKey)
          .every((key) => futureMembership[key] === false);

        if (!noCurrentSubscription && !noFutureSubscription) {
          this.currentState = State.SUBSCRIBED;
          this.hasActiveSubscription = true;
        }

        if (!noCurrentSubscription && noFutureSubscription) {
          this.currentState = State.WILL_UNSUBSCRIBE;
          this.hasActiveSubscription = true;
        }
        if (noCurrentSubscription) {
          this.currentState = State.UNSUBSCRIBED;
          this.membershipPeriod = MembershipPeriod.MONTHLY;
        }

        if (futureMembership.essentialCoaching) {
          this.withEssentielCoaching = true;
        }
        if (futureMembership.essentialSupplements) {
          this.withEssentielSupplements = true;
        }
        if (futureMembership.optionCoaching) {
          this.withCoachingOption = true;
        }
        if (futureMembership.optionWeigth) {
          this.withStructurantOption = true;
        }
        if (futureMembership.option35) {
          this.withProtecteurOption = true;
        }

        if (this.membershipPeriod === 'three_months' && this.programType === 'age') {
          this.withEssentielSupplements = true;
          this.withEssentielCoaching = true;
          this.withProtecteurOption = true;
        }

        if (this.membershipPeriod === 'three_months' && this.programType === 'sopk') {
          this.withEssentielSupplements = true;
          this.withEssentielCoaching = true;
          this.withStructurantOption = true;
        }

        this.onUpdatedOptions();
      }
    });
    this.subscriptions.push(sub);
  }

  /** cleanup */
  ngOnDestroy() {
    this.subscriptions.forEach((subs) => subs.unsubscribe());
  }

  setCurrentState(state: State) {
    this.currentState = state;
  }

  /**
   * After click on option checkbox,
   * update totalPrice
   */
  onUpdatedOptions() {
    let totalOptions = 0;
    const currentMembershipPeriod = this.membershipPeriod;

    const data = localStorage.getItem('patient');
    this.isPickup = JSON.parse(data).isPickup;

    if (this.withEssentielCoaching) {
      totalOptions += this.pricingTable.essentialCoaching[currentMembershipPeriod];
    }
    if (this.withEssentielSupplements) {
      totalOptions += this.pricingTable.essentialSupplements[currentMembershipPeriod];
    }
    if (this.withCoachingOption) {
      totalOptions += this.pricingTable.coachingOption[currentMembershipPeriod];
    }
    if (this.withProtecteurOption) {
      totalOptions += this.pricingTable.protecteurOption[currentMembershipPeriod];
    }
    if (this.withStructurantOption) {
      totalOptions += this.pricingTable.structurantOption[currentMembershipPeriod];
    }

    const nothingToShip = !this.withEssentielSupplements && !this.withProtecteurOption && !this.withStructurantOption;
    this.totalShipping = totalOptions === 0 || totalOptions >= 100 || nothingToShip ? 0 : this.isPickup ? 5 : 6.5;

    // Arrondir le total à deux décimales
    this.totalOptions = parseFloat(totalOptions.toFixed(1));
  }

  /**
   * If patient has an active subscription (currentMembership !== NONE),
   * it will update the existing subscription
   * Else, it will create a new one right away
   */
  doChangeSubscription(): void {
    if (this.hasActiveSubscription) {
      this.updateSubscription();
    } else {
      this.createSubscription();
    }
  }

  /**
   * After click on "SE REABONNER" AND "Valider mon achat",
   * update subscription via API call
   */
  createSubscription(): void {
    this.currentState = State.LOADING;
    const {
      withCoachingOption,
      withStructurantOption,
      withProtecteurOption,
      withEssentielCoaching,
      withEssentielSupplements,
      membershipPeriod,
      programType,
    } = this;
    const options = {
      withEssentielCoaching,
      withEssentielSupplements,
      withCoachingOption,
      withStructurantOption,
      withProtecteurOption,
      withShipping: !!this.totalShipping,
      isPickup: this.isPickup,
      domainURL: this.url,
      membershipPeriod,
      programType,
    };
    if (this.patient?.stripeCustomerId) {
      this.patientService.createCheckoutSession(this.patient.id, options).subscribe({
        next: (res) => {
          window.open(res.url, '_self').focus();
        },
        error: () => {
          this.notification.error('Erreur', 'Une erreur est survenue.');
          this.initState();
        },
      });
    } else {
      this.patientService.createShopifyCheckoutSession(this.patient.id, options).subscribe({
        next: (res) => {
          window.open(res.url, '_self').focus();
        },
        error: () => {
          this.notification.error('Erreur', 'Une erreur est survenue.');
          this.initState();
        },
      });
    }
  }

  handleSubscription() {
    // If patient is not a stripeCustomer, it is a shopify customer, then show shopify modal
    if (!this.patient.stripeCustomerId) {
      this.isVisibleShopifyManageSubModal = true;
      return;
    }

    // If patient is stripe customer with 3 months offer display EDIT_SUBSCRIPTION_PACK state
    if (this.isPackSubscription()) {
      this.setCurrentState(State.EDIT_SUBSCRIPTION_PACK);
      return;
    }

    // If patient is stripe customer with normal offer (not sopk) display EDIT_SUBSCRIPTION state
    this.setCurrentState(State.EDIT_SUBSCRIPTION);
  }

  /**
   * After click on "MODIFIER MES OPTIONS" AND "Valider mon achat",
   * update subscription via API call
   */
  updateSubscription(): void {
    this.currentState = State.LOADING;
    const {
      withCoachingOption,
      withStructurantOption,
      withProtecteurOption,
      withEssentielCoaching,
      withEssentielSupplements,
      membershipPeriod,
      programType,
    } = this;
    const options = {
      withEssentielCoaching,
      withEssentielSupplements,
      withCoachingOption,
      withStructurantOption,
      withProtecteurOption,
      withShipping: !!this.totalShipping,
      isPickup: this.isPickup,
      membershipPeriod,
      programType,
    };
    if (this.patient?.stripeCustomerId) {
      this.patientService.updateSubscription(this.patient.id, options).subscribe({
        next: () => {
          this.patientService.get(this.patient.id).subscribe((patient) => {
            this.patient = patient;
            this.authentificationService.currentUser = this.patient;
            // Check if coaching or supplements have been retired
            const coachingRetired = !withEssentielCoaching && patient.currentMembership.essentialCoaching;
            const supplementsRetired = !withEssentielSupplements && patient.currentMembership.essentialSupplements;

            // Determine modal type
            if (coachingRetired && supplementsRetired) {
              this.modalType = 'both';
              this.isVisible = true;
            } else if (coachingRetired) {
              this.modalType = 'coaching';
              this.isVisible = true;
            } else if (supplementsRetired) {
              this.modalType = 'supplements';
              this.isVisible = true;
            } else {
              this.isVisible = false;
            }
            this.notification.success(
              'Succès',
              "Votre abonnement a bien été mis à jour. Les changements seront effectifs dès votre prochain mois d'abonnement."
            );

            this.initState();
            this.hasJustUpdatedSubscription = true;
          });
        },
        error: (err) => {
          if (err.status === 423) {
            this.notification.error(
              'Erreur',
              'Pour vous assurer un accompagnement de qualité, vous ne pouvez modifier votre abonnement dans les 3 jours précédant votre prochain prélèvement.',
              { nzDuration: 10000 }
            );
          } else {
            this.notification.error(
              'Erreur',
              "La modification de votre programme BeMum a échoué. Veuillez contacter BeMum afin de débloquer la situation. Utilisez le chat ou l'adresse email hello@bemum.co."
            );
          }
          this.initState();
        },
      });
    } else {
      this.createShopifyPortalSession();
    }
  }

  /**
   * Does not work with unpaid Appstle subscription
   */
  createShopifyPortalSession(): void {
    const sub_ = this.patientService.createShopifyPortalSession(this.patient?.id).subscribe({
      next: (data) => {
        window.open(data.manageSubscriptionLink, '_blank');
      },
      error: (error) => {
        console.log(error);
        this.notification.error(`Une erreur est survenue`, `Veuillez réessayer un peu plus tard`);
      },
    });

    this.subscriptions.push(sub_);
  }

  // Returns true if the membership is empty (no active subscription)
  isMembershipEmpty(membership: MembershipDto) {
    const isBooleanKey = (key: string) => typeof membership[key] === 'boolean';

    return Object.keys(membership)
      .filter(isBooleanKey)
      .every((key) => membership[key] === false);
  }

  getSubscriptionText(membership: MembershipDto, isFuture = false) {
    if (this.isMembershipEmpty(membership)) {
      return isFuture ? 'Aucun' : 'Vous êtes désinscrite du programme BeMum.';
    }

    let subscription = '';
    if (membership.essentialSupplements) {
      subscription += ', Compléments alimentaires essentiels';
    }

    if (membership.essentialCoaching) {
      subscription += ', Coaching';
    }

    if (membership.optionCoaching) {
      subscription += ', Téléconsultation supplémentaires';
    }
    if (membership.option35) {
      subscription += ', Spécificité  + 35 ans';
    }
    if (membership.optionWeigth) {
      subscription += ', Spécificité poids / SOPK';
    }

    return subscription.slice(1);
  }

  // Détermine si le bouton "Gérer mon abonnement" doit être affiché
  shouldDisplaySubscriptionButton(): boolean {
    // Cacher le bouton si l'un des abonnements spécifiques est actif
    return this.patient.currentMembership.membershipPeriod === 'six_months';
  }

  // Vérifie si l'un des abonnements spécifiques est actif dans le currentMembership ou le futureMembership
  isPackSubscription(): boolean {
    const isCurrentPack = this.patient && this.patient.currentMembership.membershipPeriod === 'three_months';
    return isCurrentPack;
  }
  handleReinscription(): void {
    if (this.hasActiveSubscription) {
      if (this.patient.currentMembership.membershipPeriod === 'three_months') {
        this.setCurrentState(State.CREATE_SUBSCRIPTION_PACK);
      } else {
        this.setCurrentState(State.CREATE_SUBSCRIPTION);
      }
    } else {
      this.choiceRegister = true;
    }
  }

  handleModalClose = () => {
    // this.isVisibleCoachingDropOutModal = false;
    // this.isVisibleSupplementsDropOutModal = false;
    this.isVisible = false;
    this.isVisibleShopifyManageSubModal = false;
    this.choiceRegister = false;
  };

  handleOk = () => {
    switch (this.selectedCheckbox) {
      case 'packAge':
        this.programType = ProgramType.AGE;
        this.setCurrentState(State.CREATE_SUBSCRIPTION_PACK_OPTION);
        break;
      case 'packSopk':
        this.programType = ProgramType.SOPK;
        this.setCurrentState(State.CREATE_SUBSCRIPTION_PACK_OPTION);
        break;
      default:
        this.programType = ProgramType.ESSENTIAL;
        this.setCurrentState(State.CREATE_SUBSCRIPTION);
        break;
    }
    this.choiceRegister = false;
  };

  onPackOptionChange(option: 'monthly' | 'three_months') {
    this.monthSelect = option;

    if (option === 'monthly') {
      this.totalPackOptions = this.programType === 'sopk' ? 78 : 81.3;
      this.totalShipping = this.isPickup ? 5 : 6.5;
      this.totalPackOptions += this.isPickup ? 5 : 6.5;
      this.membershipPeriod = MembershipPeriod.MONTHLY;
    } else if (option === 'three_months') {
      this.totalPackOptions = this.programType === 'sopk' ? 199 : 207;
      this.totalShipping = 0;
      this.membershipPeriod = MembershipPeriod.THREE_MONTHS;
    }
  }

  initRenewalState(): void {
    this.monthSelect = '';
    this.selectedCheckbox = '';
    this.totalPackOptions = 0;
    this.initState();
  }

  doRenewalSubscription(): void {
    if (this.programType === 'age') {
      this.withEssentielCoaching = true;
      this.withEssentielSupplements = true;
      this.withProtecteurOption = true;

      this.withCoachingOption = false;
      this.withStructurantOption = false;
    }
    if (this.programType === 'sopk') {
      this.withEssentielCoaching = true;
      this.withEssentielSupplements = true;
      this.withStructurantOption = true;

      this.withCoachingOption = false;
      this.withProtecteurOption = false;
    }

    this.createSubscription();
  }
}
